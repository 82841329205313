@import "../../Common/variables.module.scss";

.container {
    position: fixed;
    bottom: 0px;
    left: $padding;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 92vw;
}

.alert {
    width: fit-content;
    min-width: 300px;
}

.snackbar {
    z-index: 30000000 !important;
}
