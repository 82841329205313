@import "../../Common/variables.module.scss";

.outterContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    padding: $padding;
}

.textfield {
    width: 80%;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    padding: $padding;
}
